// Here you can add other styles
*{
  user-select: none;
}


.header{
  background-color: #e9ecef;
}

.profileInfo{
    .profilelable {
      color: #B2B2B2;
      font-size: 15px;
    }
    
    .bgcolor {
      background: rgb(205, 237, 236);
      background: linear-gradient(90deg, rgba(205, 237, 236, 1) 0%, rgba(120, 185, 239, 1) 100%);
      height: 100px;
    }
    .profileima{
      border-radius: 50%;
      height: 130px;
      /* padding-left: 100px; */
      margin-left: 35px;
      position: relative;
    bottom: 15px;
    }
    .modal-dialog{
      max-width: 1000px !important;
    }

  }
      
  @media (min-width: 1500px) and (max-width: 2500px) {
    .container-lg{
        max-width: 1800px;
    }
  }

  // modal width

  .custom-modal-width {
    width: 100%;
    // margin: auto;
  }

  .login{
    color: antiquewhite;
  }

  .register-card{
    display: flex;
    justify-content: center;
  }


  .ul-list{
    list-style: none;
  }

  .editBtn{
    border: none ;
    background: none;
  }
  
  .event-heading{
    display: flex;
    // gap: 2rem;
    justify-content: space-between;
    align-items: center;

    .event-btn{
      background-color: #ef8129;
      outline: none;
      border: none;
      width: 10rem;
    }

    

  }

  .Card_form{
    width: 55rem;
  }

  .form_nav{
    border-bottom: 2px solid #f0f0f0;
  }

  .form_nav--mr{
    border-right: 2px solid #f0f0f0 ;
  }

  .add_form{
    width: 30rem;
  }

  .popover_btns{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .popover_btn{
      background-color: transparent;
      color: #222;
      outline: none;
      border: none;
      border-bottom: 1.7px solid #f0f0f0;
    }
  }

  .pre_table_head{
    display: flex;
    justify-content: flex-end;
  }
 
//   .circular-progress {
//     width: 100px; /* Adjust size as needed */
//     height: 100px; /* Adjust size as needed */
//   }
  
//   .circular-progress svg {
//     transform: rotate(-90deg);
//   }
  
//   .circular-progress .progress-circle {
//     fill: none;
//     stroke: #ddd; /* Background color */
//     stroke-width: 10; /* Adjust thickness as needed */
//   }
  
//   .circular-progress .progress-bar {
//     fill: none;
//     stroke: #5856D6; /* Progress color */
//     stroke-width: 10; /* Adjust thickness as needed */
//     stroke-dasharray: 283; /* Circumference of a circle with radius 45 */
//     stroke-dashoffset: calc(283 - (283 * 50) / 100); /* Percentage value */
//     transition: stroke-dashoffset 0.3s ease;
//   }
//   .circular-progress .progress-value {
//   font-size: 15px; /* Adjust font size as needed */
//   fill: #000; /* Text color */
// }

// .circular-progress .progress-circle {
//   fill: none;
//   stroke: #ddd; /* Background color */
//   stroke-width: 10; /* Adjust thickness as needed */
// }

// .circular-progress .progress-bar {
//   fill: none;
//   stroke: #007bff; /* Progress color */
//   stroke-width: 10; /* Adjust thickness as needed */
//   stroke-dasharray: 283; /* Circumference of a circle with radius 45 */
//   stroke-dashoffset: calc(283 - (283 * 50) / 100); /* Percentage value */
//   transition: stroke-dashoffset 0.3s ease;
// }

// .circular-progress .progress-value {
//   font-size: 20px; /* Adjust font size as needed */
//   fill: #000; /* Text color */
// }

.score {
  position: fixed;
  width: -webkit-fill-available;
  top: 115px;
  z-index: 999;
  // background-color: white; /* Adjust as needed */
  // border-bottom: 1px solid #ccc; /* Adjust as needed */
}

// .content {
//   margin-top: 100px;
// }


.profile-table {
  background-color: transparent;
  border: none;
}

.profile-table thead th {
  background-color: transparent;
  border: none;
}

.profile-table tbody td {
  background-color: transparent;
  border: none;
}
.profileimg{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.profileremove {
  margin-left: 15px;
}
.profilebg{
  background-color:#F4F4F4 ;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  margin: auto;
  display: block;
}

// -------------------------   profile mobile responsive ---------------

@media (min-width: 0px) and (max-width: 767px) {
	.profileweb {
		display: none;
	}
  .profileimamob{
    border-radius: 50%;
    height: 100px;
    /* padding-left: 100px; */
    margin-left: 10px;
  }
  .texbut{
    margin-top: 60px;
    margin-left: 30px;
  }
}
@media (min-width: 768px) and (max-width: 5000px) {
	.profilemobi {
		display: none;
	}

}
